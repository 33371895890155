<svg xmlns="http://www.w3.org/2000/svg" width="109" height="48" viewBox="0 0 109 48" fill="none">
    <path d="M30.2798 27.7717H12.8135V28.114C12.8135 30.5122 13.488 32.4494 14.8373 33.9258C16.1862 35.4023 17.945 36.1401 20.1133 36.1401C21.5909 36.1401 22.9237 35.7362 24.1124 34.9288C25.3006 34.1214 26.3527 32.9021 27.2685 31.2704L30.1353 33.2284C29.0273 35.2511 27.6134 36.7763 25.8952 37.8041C24.1765 38.8319 22.1851 39.3453 19.9203 39.3453C16.5634 39.3453 13.8494 38.2196 11.7776 35.9689C9.70582 33.7175 8.66992 30.7654 8.66992 27.1111C8.66992 23.6039 9.69753 20.733 11.7536 18.4979C13.8093 16.2633 16.4512 15.1455 19.6798 15.1455C22.9398 15.1455 25.5215 16.2061 27.4252 18.3263C29.3281 20.4475 30.2798 23.3428 30.2798 27.0131V27.7717ZM26.2322 24.664C26.1039 22.6901 25.4573 21.1689 24.2931 20.1003C23.1284 19.0322 21.5346 18.4979 19.5109 18.4979C17.68 18.4979 16.1539 19.0566 14.9338 20.1739C13.7128 21.2912 13.0065 22.7881 12.8135 24.664H26.2322Z" fill="white"/>
    <path d="M32.1183 38.3914L40.5984 26.8174L32.3588 16.002H37.0568L42.7667 24.0037L48.5486 16.002H53.2462L44.9345 26.8174L53.4871 38.3914H48.7896L42.7667 29.7291L36.8158 38.3914H32.1183Z" fill="white"/>
    <path d="M55.5488 27.2589C55.5488 25.6112 55.8457 24.0576 56.4402 22.5971C57.0344 21.1375 57.9013 19.8366 59.042 18.6945C60.1822 17.5528 61.4752 16.6842 62.9206 16.0883C64.3664 15.4933 65.9083 15.1953 67.5466 15.1953C69.1522 15.1953 70.6739 15.4977 72.1119 16.1007C73.5489 16.7046 74.8537 17.5856 76.0267 18.7433C77.1665 19.869 78.0344 21.1619 78.6285 22.622C79.2222 24.082 79.5199 25.6277 79.5199 27.2589C79.5199 28.8901 79.2222 30.4277 78.6285 31.8709C78.0344 33.315 77.1665 34.5995 76.0267 35.7252C74.8537 36.8998 73.5411 37.7972 72.0874 38.417C70.6338 39.0364 69.1203 39.3463 67.5466 39.3463C65.924 39.3463 64.3861 39.0404 62.9328 38.429C61.4792 37.8171 60.1822 36.9405 59.042 35.7984C57.9013 34.6239 57.0344 33.319 56.4402 31.8833C55.8457 30.4481 55.5488 28.9065 55.5488 27.2589ZM59.5479 27.2589C59.5479 29.6243 60.3267 31.6266 61.8851 33.2658C63.4427 34.9054 65.3298 35.7252 67.5466 35.7252C69.7467 35.7252 71.6177 34.9054 73.1596 33.2658C74.7014 31.6266 75.4723 29.6243 75.4723 27.2589C75.4723 24.8934 74.7014 22.8911 73.1596 21.2515C71.6177 19.6123 69.7467 18.7925 67.5466 18.7925C65.3137 18.7925 63.4226 19.6123 61.8729 21.2515C60.3228 22.8911 59.5479 24.8934 59.5479 27.2589Z" fill="white"/>
    <path d="M86.3977 38.392V19.1594H82.1572V16.0025H86.3977V9.51853C86.3977 5.799 86.8592 3.28324 87.7829 1.9695C88.7061 0.656649 90.3645 0 92.7576 0C93.3513 0 93.9179 0.0407912 94.4562 0.122373C94.994 0.203956 95.5117 0.33431 96.0098 0.51388V4.47772C95.5283 4.2006 95.042 3.99665 94.5522 3.86585C94.0624 3.73594 93.5604 3.67032 93.0466 3.67032C91.9382 3.67032 91.1756 4.02946 90.7583 4.74685C90.3401 5.46513 90.1314 7.22669 90.1314 10.0324V16.0025H96.0098V19.1594H90.2279V38.392H86.3977Z" fill="white"/>
    <path d="M0 16.0029H8.33391V19.1598H0" fill="#69B518"/>
    <path d="M0 16.0029H8.33391V17.5819H0" fill="#69B518"/>
    <path d="M100.248 19.1595H95.8359V16.0027H100.248V6.97363H104.103V16.0027H108.15V19.1595H104.103V38.3921H100.248V19.1595Z" fill="white"/>
    <path d="M82.1572 16.002H108.152V19.1588H82.1572" fill="#69B518"/>
    <path d="M82.1572 16.002H108.152V17.581H82.1572" fill="#69B518"/>
    <path d="M37.2782 47.8711V43.9773C37.2782 43.8595 37.1953 43.781 37.0793 43.781H36.5601C36.444 43.781 36.3667 43.8371 36.317 43.9325L35.4718 45.5315C35.4497 45.5764 35.4332 45.6044 35.4111 45.6044C35.389 45.6044 35.3724 45.5764 35.3503 45.5315L34.5051 43.9325C34.4554 43.8371 34.3781 43.781 34.2621 43.781H33.7428C33.6268 43.781 33.5439 43.8539 33.5439 43.9717V47.8711C33.5439 47.9328 33.5937 47.9497 33.6379 47.9497H34.367C34.4167 47.9497 34.4554 47.8992 34.4554 47.8375V45.4978C34.4554 45.4585 34.472 45.4529 34.483 45.4529C34.4996 45.4529 34.5106 45.4642 34.5272 45.4922L35.0244 46.418C35.063 46.4853 35.1183 46.5133 35.1901 46.5133H35.632C35.7038 46.5133 35.7591 46.4853 35.7977 46.418L36.2949 45.4922C36.3115 45.4642 36.3225 45.4529 36.3391 45.4529C36.3501 45.4529 36.3667 45.4585 36.3667 45.4978V47.8375C36.3667 47.8992 36.4054 47.9497 36.4551 47.9497H37.1843C37.2285 47.9497 37.2782 47.9328 37.2782 47.8711Z" fill="white"/>
    <path d="M40.8931 47.8094V47.2876C40.8931 47.2259 40.8434 47.1754 40.7826 47.1754H39.6613C39.4016 47.1754 39.3077 47.0856 39.3077 46.7939V46.1991H40.7384C40.7992 46.1991 40.8489 46.1487 40.8489 46.0869V45.5539C40.8489 45.4922 40.7992 45.4417 40.7384 45.4417H39.3077V44.9311C39.3077 44.6394 39.4016 44.5496 39.6613 44.5496H40.7826C40.8434 44.5496 40.8931 44.4991 40.8931 44.4374V43.9156C40.8931 43.8427 40.86 43.8146 40.7826 43.8034C40.5893 43.7754 40.3131 43.7305 39.7165 43.7305C39.0039 43.7305 38.3686 43.91 38.3686 44.9311V46.7939C38.3686 47.815 38.9984 48.0002 39.711 48.0002C40.3076 48.0002 40.5893 47.9553 40.7826 47.9272C40.86 47.916 40.8931 47.8823 40.8931 47.8094Z" fill="white"/>
    <path d="M45.6552 47.8711V43.9773C45.6552 43.8595 45.5724 43.781 45.4564 43.781H44.9371C44.8211 43.781 44.7438 43.8371 44.6941 43.9325L43.8489 45.5315C43.8268 45.5764 43.8102 45.6044 43.7881 45.6044C43.766 45.6044 43.7494 45.5764 43.7274 45.5315L42.8822 43.9325C42.8325 43.8371 42.7551 43.781 42.6391 43.781H42.1199C42.0039 43.781 41.921 43.8539 41.921 43.9717V47.8711C41.921 47.9328 41.9707 47.9497 42.0149 47.9497H42.7441C42.7938 47.9497 42.8325 47.8992 42.8325 47.8375V45.4978C42.8325 45.4585 42.849 45.4529 42.8601 45.4529C42.8767 45.4529 42.8877 45.4642 42.9043 45.4922L43.4014 46.418C43.4401 46.4853 43.4953 46.5133 43.5672 46.5133H44.0091C44.0809 46.5133 44.1361 46.4853 44.1748 46.418L44.672 45.4922C44.6885 45.4642 44.6996 45.4529 44.7162 45.4529C44.7272 45.4529 44.7438 45.4585 44.7438 45.4978V47.8375C44.7438 47.8992 44.7824 47.9497 44.8322 47.9497H45.5613C45.6055 47.9497 45.6552 47.9328 45.6552 47.8711Z" fill="white"/>
    <path d="M49.817 46.7434V46.6592C49.817 46.2721 49.6182 45.9747 49.3254 45.8288C49.6182 45.683 49.7894 45.3688 49.7894 45.0041V44.9031C49.7894 44.011 49.0879 43.7305 48.0438 43.7305C47.6903 43.7305 47.2429 43.7641 47.055 43.7922C46.8396 43.8258 46.7623 43.9044 46.7623 44.1569V47.5681C46.7623 47.8206 46.8451 47.8936 47.0661 47.9328C47.2815 47.9721 47.7124 48.0002 48.077 48.0002C49.0326 48.0002 49.817 47.7309 49.817 46.7434ZM48.878 45.049C48.878 45.2902 48.7509 45.4922 48.3145 45.4922H47.6958V44.5216C47.7732 44.516 48.0273 44.516 48.1157 44.516C48.6846 44.516 48.878 44.6562 48.878 44.9648V45.049ZM48.9001 46.7209C48.9001 47.0351 48.7178 47.2147 48.1488 47.2147C48.0715 47.2147 47.7842 47.2091 47.6958 47.2091V46.1599H48.3145C48.7564 46.1599 48.9001 46.3899 48.9001 46.6592V46.7209Z" fill="white"/>
    <path d="M53.2969 47.8094V47.2876C53.2969 47.2259 53.2471 47.1754 53.1864 47.1754H52.065C51.8054 47.1754 51.7115 47.0856 51.7115 46.7939V46.1991H53.1422C53.203 46.1991 53.2527 46.1487 53.2527 46.0869V45.5539C53.2527 45.4922 53.203 45.4417 53.1422 45.4417H51.7115V44.9311C51.7115 44.6394 51.8054 44.5496 52.065 44.5496H53.1864C53.2471 44.5496 53.2969 44.4991 53.2969 44.4374V43.9156C53.2969 43.8427 53.2637 43.8146 53.1864 43.8034C52.993 43.7754 52.7168 43.7305 52.1203 43.7305C51.4077 43.7305 50.7724 43.91 50.7724 44.9311V46.7939C50.7724 47.815 51.4021 48.0002 52.1147 48.0002C52.7113 48.0002 52.993 47.9553 53.1864 47.9272C53.2637 47.916 53.2969 47.8823 53.2969 47.8094Z" fill="white"/>
    <path d="M57.4955 47.8823C57.4955 47.8655 57.49 47.8431 57.479 47.8206L56.6448 46.2384C57.0481 46.0757 57.3574 45.711 57.3574 45.1163V45.0434C57.3574 44.0503 56.6338 43.7305 55.6947 43.7305C55.297 43.7305 54.8274 43.7641 54.5733 43.8034C54.4021 43.8315 54.3248 43.882 54.3248 44.0727V47.8375C54.3248 47.8992 54.3745 47.9497 54.4352 47.9497H55.1534C55.2141 47.9497 55.2638 47.8992 55.2638 47.8375V46.4124C55.3688 46.4124 55.54 46.4236 55.656 46.4236L55.7168 46.418L56.457 47.8375C56.4846 47.8936 56.5067 47.9497 56.5675 47.9497H57.4127C57.4734 47.9497 57.4955 47.9216 57.4955 47.8823ZM56.4184 45.1163C56.4184 45.4978 56.2361 45.6493 55.656 45.6493C55.5953 45.6493 55.3246 45.6437 55.2638 45.6381V44.5608C55.3301 44.5552 55.6174 44.544 55.6947 44.544C56.1587 44.544 56.4184 44.7011 56.4184 45.0434V45.1163Z" fill="white"/>
    <path d="M63.3535 46.1991V45.5315C63.3535 44.2298 62.6298 43.7305 61.7184 43.7305C60.8069 43.7305 60.0833 44.2298 60.0833 45.5315V46.1991C60.0833 47.5008 60.8069 48.0002 61.7184 48.0002C62.6298 48.0002 63.3535 47.5008 63.3535 46.1991ZM62.4033 46.1991C62.4033 46.8724 62.1824 47.1586 61.7184 47.1586C61.2544 47.1586 61.0389 46.8724 61.0389 46.1991V45.5315C61.0389 44.8582 61.2544 44.5721 61.7184 44.5721C62.1824 44.5721 62.4033 44.8582 62.4033 45.5315V46.1991Z" fill="white"/>
    <path d="M66.8736 44.443V43.9212C66.8736 43.8483 66.8405 43.8202 66.7631 43.809C66.5698 43.781 66.2936 43.7361 65.697 43.7361C64.9844 43.7361 64.3491 43.9156 64.3491 44.9368V47.8375C64.3491 47.8992 64.3989 47.9497 64.4596 47.9497H65.1777C65.2385 47.9497 65.2882 47.8992 65.2882 47.8375V46.1991H66.7189C66.7797 46.1991 66.8294 46.1487 66.8294 46.0869V45.5539C66.8294 45.4922 66.7797 45.4417 66.7189 45.4417H65.2882V44.9368C65.2882 44.645 65.3821 44.5552 65.6418 44.5552H66.7631C66.8239 44.5552 66.8736 44.5047 66.8736 44.443Z" fill="white"/>
    <path d="M72.1812 47.8094V47.2876C72.1812 47.2259 72.1315 47.1754 72.0707 47.1754H70.9493C70.6897 47.1754 70.5958 47.0856 70.5958 46.7939V46.1991H72.0265C72.0873 46.1991 72.137 46.1487 72.137 46.0869V45.5539C72.137 45.4922 72.0873 45.4417 72.0265 45.4417H70.5958V44.9311C70.5958 44.6394 70.6897 44.5496 70.9493 44.5496H72.0707C72.1315 44.5496 72.1812 44.4991 72.1812 44.4374V43.9156C72.1812 43.8427 72.148 43.8146 72.0707 43.8034C71.8774 43.7754 71.6012 43.7305 71.0046 43.7305C70.292 43.7305 69.6567 43.91 69.6567 44.9311V46.7939C69.6567 47.815 70.2864 48.0002 70.999 48.0002C71.5956 48.0002 71.8774 47.9553 72.0707 47.9272C72.148 47.916 72.1812 47.8823 72.1812 47.8094Z" fill="white"/>
    <path d="M76.3798 46.3843V43.8876C76.3798 43.8258 76.3246 43.781 76.2638 43.781H75.5457C75.485 43.781 75.4352 43.8258 75.4352 43.8876V46.3843C75.4352 46.9566 75.2806 47.1978 74.7834 47.1978C74.2863 47.1978 74.1371 46.9566 74.1371 46.3843V43.8876C74.1371 43.8258 74.0874 43.781 74.0266 43.781H73.303C73.2422 43.781 73.1925 43.8258 73.1925 43.8876V46.3843C73.1925 47.6635 73.9051 48.0002 74.7834 48.0002C75.6617 48.0002 76.3798 47.6635 76.3798 46.3843Z" fill="white"/>
    <path d="M80.7158 43.8707C80.7158 43.8146 80.6772 43.781 80.6219 43.781H79.8486C79.7933 43.781 79.7547 43.8258 79.7436 43.882L79.0531 47.1193C79.0421 47.181 79.0255 47.2035 78.9758 47.2035C78.9261 47.2035 78.9095 47.181 78.8984 47.1193L78.2079 43.882C78.1969 43.8258 78.1527 43.781 78.0975 43.781H77.3296C77.2744 43.781 77.2302 43.8202 77.2302 43.8707C77.2302 43.882 77.2357 43.8876 77.2357 43.8988L78.0588 47.3774C78.1859 47.916 78.5946 48.0002 78.9758 48.0002C79.3569 48.0002 79.7602 47.916 79.8872 47.3774L80.7103 43.8988C80.7103 43.8876 80.7158 43.8763 80.7158 43.8707Z" fill="white"/>
    <path d="M82.5307 47.8375V43.8876C82.5307 43.8258 82.4865 43.781 82.4258 43.781H81.6856C81.6248 43.781 81.5861 43.8258 81.5861 43.8876V47.8375C81.5861 47.8992 81.6248 47.9497 81.6856 47.9497H82.4258C82.4865 47.9497 82.5307 47.8992 82.5307 47.8375Z" fill="white"/>
    <path d="M86.1188 47.7757V47.2315C86.1188 47.1642 86.0857 47.1193 86.0139 47.1193H85.9973C85.7874 47.1361 85.4836 47.1642 85.1798 47.1642C84.7986 47.1642 84.4948 46.9622 84.4948 46.216V45.509C84.4948 44.7628 84.7986 44.5608 85.1798 44.5608C85.4836 44.5608 85.7874 44.5889 85.9973 44.6057H86.0139C86.0857 44.6057 86.1188 44.5608 86.1188 44.4935V43.9493C86.1188 43.8707 86.0746 43.8483 85.9973 43.8258C85.8813 43.7866 85.5609 43.7305 85.1742 43.7305C84.3346 43.7305 83.5391 44.1737 83.5391 45.509V46.216C83.5391 47.5513 84.3346 48.0002 85.1742 48.0002C85.5609 48.0002 85.8813 47.9441 85.9973 47.9048C86.0746 47.8823 86.1188 47.8543 86.1188 47.7757Z" fill="white"/>
    <path d="M91.6446 44.5384V43.9437C91.6446 43.8932 91.6059 43.8707 91.5507 43.8595C91.3463 43.8146 90.9485 43.7305 90.3961 43.7305C89.5952 43.7305 88.7168 44.0895 88.7168 45.5203V46.216C88.7168 47.6355 89.6614 48.0002 90.2912 48.0002C90.8436 48.0002 91.2303 47.9441 91.5286 47.8823C91.6114 47.8655 91.639 47.8318 91.639 47.7533V45.8681C91.639 45.8064 91.5893 45.7559 91.5286 45.7559H90.8215C90.7607 45.7559 90.7165 45.8064 90.7165 45.8681V47.1586C90.584 47.1698 90.4459 47.1754 90.2912 47.1754C90.0205 47.1754 89.6725 46.9959 89.6725 46.2216V45.5203C89.6725 44.7853 89.9487 44.5665 90.3961 44.5665C90.8657 44.5665 91.28 44.6001 91.5451 44.6113H91.5617C91.6114 44.6113 91.6446 44.6001 91.6446 44.5384Z" fill="white"/>
    <path d="M95.8899 47.8823C95.8899 47.8655 95.8844 47.8431 95.8734 47.8206L95.0392 46.2384C95.4425 46.0757 95.7518 45.711 95.7518 45.1163V45.0434C95.7518 44.0503 95.0282 43.7305 94.0891 43.7305C93.6914 43.7305 93.2218 43.7641 92.9677 43.8034C92.7965 43.8315 92.7192 43.882 92.7192 44.0727V47.8375C92.7192 47.8992 92.7689 47.9497 92.8296 47.9497H93.5477C93.6085 47.9497 93.6582 47.8992 93.6582 47.8375V46.4124C93.7632 46.4124 93.9344 46.4236 94.0504 46.4236L94.1112 46.418L94.8514 47.8375C94.879 47.8936 94.9011 47.9497 94.9619 47.9497H95.8071C95.8678 47.9497 95.8899 47.9216 95.8899 47.8823ZM94.8127 45.1163C94.8127 45.4978 94.6305 45.6493 94.0504 45.6493C93.9897 45.6493 93.719 45.6437 93.6582 45.6381V44.5608C93.7245 44.5552 94.0118 44.544 94.0891 44.544C94.5531 44.544 94.8127 44.7011 94.8127 45.0434V45.1163Z" fill="white"/>
    <path d="M99.8628 46.1991V45.5315C99.8628 44.2298 99.1392 43.7305 98.2277 43.7305C97.3162 43.7305 96.5926 44.2298 96.5926 45.5315V46.1991C96.5926 47.5008 97.3162 48.0002 98.2277 48.0002C99.1392 48.0002 99.8628 47.5008 99.8628 46.1991ZM98.9127 46.1991C98.9127 46.8724 98.6917 47.1586 98.2277 47.1586C97.7637 47.1586 97.5482 46.8724 97.5482 46.1991V45.5315C97.5482 44.8582 97.7637 44.5721 98.2277 44.5721C98.6917 44.5721 98.9127 44.8582 98.9127 45.5315V46.1991Z" fill="white"/>
    <path d="M104.046 46.3843V43.8876C104.046 43.8258 103.991 43.781 103.93 43.781H103.212C103.151 43.781 103.101 43.8258 103.101 43.8876V46.3843C103.101 46.9566 102.947 47.1978 102.449 47.1978C101.952 47.1978 101.803 46.9566 101.803 46.3843V43.8876C101.803 43.8258 101.753 43.781 101.693 43.781H100.969C100.908 43.781 100.858 43.8258 100.858 43.8876V46.3843C100.858 47.6635 101.571 48.0002 102.449 48.0002C103.328 48.0002 104.046 47.6635 104.046 46.3843Z" fill="white"/>
    <path d="M108.161 45.1331V45.0826C108.161 44.0278 107.338 43.7305 106.504 43.7305C106.106 43.7305 105.664 43.7585 105.382 43.8034C105.211 43.8315 105.134 43.882 105.134 44.0727V47.8431C105.134 47.9048 105.183 47.9497 105.244 47.9497H105.957C106.018 47.9497 106.067 47.8992 106.067 47.8375V46.4741C106.205 46.4797 106.382 46.4909 106.515 46.4909C107.338 46.4909 108.161 46.1823 108.161 45.1331ZM107.222 45.1331C107.222 45.5147 107.006 45.6998 106.515 45.6998C106.454 45.6998 106.128 45.6942 106.067 45.6886V44.544C106.134 44.5384 106.437 44.5272 106.515 44.5272C106.979 44.5272 107.222 44.6843 107.222 45.0826V45.1331Z" fill="white"/>
</svg>